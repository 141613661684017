import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useState } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'center',
    },
    '& .MuiDialog-paper': {
      width: (props) => props.width || 500,
      background: '#FFFFFF',
      borderRadius: 4,
    },
  },
  title: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
  },
  content: {
    padding: theme.spacing(3),

    '& > p': {
      marginBottom: 6,
    },
  },
  action: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[500],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailModal = ({ open, handleClose, onSubmit }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!email) {
      setError('Email không được để trống');
      return;
    }
    onSubmit(email);
    setEmail('');
    handleClose();
  };

  useEffect(() => {
    return () => {
      setEmail('');
      setError('');
    };
  }, [open]);

  return (
    <Dialog className={classes.root} open={open} onClose={handleClose} TransitionComponent={Transition} keepMounted>
      <DialogTitle className={classes.title}>Email</DialogTitle>
      <DialogContent>
        <DialogContentText>Nhập email để gửi báo cáo (các email cách nhau bởi dấu chấm phẩy)</DialogContentText>
        <TextField
          placeholder="Nhập email"
          autoFocus
          fullWidth
          multiline
          minRows={3}
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          error={error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Đóng
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Xuất báo cáo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailModal;
