export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = '@snackbar/SNACKBAR_CLOSE';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const FOLDER_CHANGE = '@folder/CHANGE';
export const SELECTED_FOLDER_CHANGE = '@folder/SELECTED_FOLDER_CHANGE';
export const AUTH_LOGIN = '@auth/LOGIN';
export const HOME_CHANGE = '@home/CHANGE';
export const PROJECT_CHANGE = '@project/CHANGE';
export const FLOATING_MENU_CHANGE = '@floatingMenu/CHANGE';
export const LOADING_CHANGE = '@loading/CHANGE';
export const VIEW_CHANGE = '@view/CHANGE';
export const CONFIRM_CHANGE = '@confirm/CHANGE';
export const DOCUMENT_CHANGE = '@document/CHANGE';
export const NEWS_CHANGE = '@news/CHANGE';
export const TASK_CHANGE = '@task/CHANGE_TASK';
export const APP_CHANGE = '@app/CHANGE_APP';
export const SELECTED_APP_CHANGE = '@app/SELECTED_APP_CHANGE';
export const METADATA = '@share/METADATA';
export const ORDER_LIST = '@order/ORDER_LIST';
export const ORDER_CHANGE = '@order/ORDER_CHANGE';
export const MATERIAL_CHANGE = '@order/MATERIAL_CHANGE';
export const ORDER_DETAIL_CHANGE = '@order/ORDER_DETAIL_CHANGE';
export const ADD_MATERIAL = '@order/ADD_MATERIAL';
export const REMOVE_MATERIAL = '@order/REMOVE_MATERIAL';
export const SET_MATERIAL = '@order/SET_MATERIAL';
export const CLOSE_MODAL_MATERIAL = '@order/CLOSE_MODAL_MATERIAL';
export const MATERIAL_RECEIVED = '@order/MATERIAL_RECEIVED';
export const PRODUCT_RECEIVED = '@order/PRODUCT_RECEIVED';
export const CLOSE_MODAL_PRODUCT = '@order/CLOSE_MODAL_PRODUCT';
export const MATERIAL_RETURN = '@material/MATERIAL_RETURN';
